import { ManagedMedicineItemType } from '@services/myDoctor/types';
import searchMedicineListJson from './searchMedicineList.json';

export const medicineGenericCodeKoreanUnit: Record<string, string> = {
  '159002ATB': '알',
  '458801ACS': '알',
  '458801ATB': '알',
  '612230BIJ': '펜',
  '708607BIJ': '펜',
  '708608BIJ': '펜',
  '708609BIJ': '펜',
  '708610BIJ': '펜',
  '708611BIJ': '펜',
  '178701ACS': '알',
  '135639CLQ': '개',
  '135635CLQ': '개',
  '135633CLQ': '개',
  '135602CLQ': '개',
  '135636CLQ': '개',
  '371201CCM': '개',
};

export const medicineGenericCodeUnit: Record<string, string> = {
  '159002ATB': 'PILL',
  '458801ACS': 'PILL',
  '458801ATB': 'PILL',
  '612230BIJ': 'PEN',
  '708607BIJ': 'PEN',
  '708608BIJ': 'PEN',
  '708609BIJ': 'PEN',
  '708610BIJ': 'PEN',
  '708611BIJ': 'PEN',
  '178701ACS': 'PILL',
  '135639CLQ': 'EA',
  '135635CLQ': 'EA',
  '135633CLQ': 'EA',
  '135602CLQ': 'EA',
  '135636CLQ': 'EA',
  '371201CCM': 'EA',
};

export const wegovyGenericCodes = [
  '708607BIJ',
  '708608BIJ',
  '708609BIJ',
  '708610BIJ',
  '708611BIJ',
];

// 위 가격 미만으로 등록할 수 없다.
export const medicineGenericNameCodePriceLimit: Record<string, number> = {
  '159002ATB': 290, // 피나스테리드
  '458801ACS': 340, // 두타스테리드
  '458801ATB': 340, // 두타스테리드
  '612230BIJ': 75000, // 삭센다
  '708607BIJ': 350000, // 위고비
  '708608BIJ': 350000, // 위고비
  '708609BIJ': 350000, // 위고비
  '708610BIJ': 350000, // 위고비
  '708611BIJ': 350000, // 위고비
  '178701ACS': 170, // 먹는 여드름약
  '135639CLQ': 7000, // 바르는 여드름약
  '135635CLQ': 7000, // 바르는 여드름약
  '135633CLQ': 7000, // 바르는 여드름약
  '135602CLQ': 7000, // 바르는 여드름약
  '135636CLQ': 7000, // 바르는 여드름약
  '371201CCM': 7000, // 바르는 여드름약
};

export interface MedicineCategoryType {
  id: number;
  label: string;
  codes: string[];
}

export const medicineCategoryList: MedicineCategoryType[] = [
  {
    id: 1,
    label: `피나스테리드`,
    codes: ['159002ATB'],
  },
  {
    id: 2,
    label: `두타스테리드`,
    codes: ['458801ACS', '458801ATB'],
  },
  {
    id: 3,
    label: `다이어트 주사`,
    codes: [
      '612230BIJ',
      '708607BIJ',
      '708608BIJ',
      '708609BIJ',
      '708610BIJ',
      '708611BIJ',
    ],
  },
  {
    id: 4,
    label: `먹는 여드름약`,
    codes: ['178701ACS'],
  },
  {
    id: 5,
    label: `바르는 여드름약`,
    codes: [
      '135639CLQ',
      '135635CLQ',
      '135633CLQ',
      '135602CLQ',
      '135636CLQ',
      '371201CCM',
    ],
  },
];

export interface MedicineCompoundingFeeConfigValueType {
  quantity: number;
  price: number | null;
}

export type MedicineCompoundingFeeConfigType = Record<
  string,
  MedicineCompoundingFeeConfigValueType[]
>;

export const medicineCompoundingFeeConfig: MedicineCompoundingFeeConfigType = {
  피나스테리드: [
    { quantity: 28, price: null },
    { quantity: 30, price: null },
    { quantity: 56, price: null },
    { quantity: 60, price: null },
    { quantity: 84, price: null },
    { quantity: 90, price: null },
  ],
  두타스테리드: [
    { quantity: 30, price: null },
    { quantity: 60, price: null },
    { quantity: 90, price: null },
  ],
  '다이어트 주사': [
    { quantity: 1, price: null },
    { quantity: 2, price: null },
    { quantity: 3, price: null },
    { quantity: 4, price: null },
    { quantity: 5, price: null },
  ],
  '먹는 여드름약': [
    { quantity: 30, price: null },
    { quantity: 45, price: null },
    { quantity: 50, price: null },
    { quantity: 60, price: null },
    { quantity: 90, price: null },
  ],
  '바르는 여드름약': [
    { quantity: 1, price: null },
    { quantity: 2, price: null },
    { quantity: 3, price: null },
    { quantity: 4, price: null },
    { quantity: 5, price: null },
  ],
};

export const findCompoundingFee = (
  generic_name_code: string,
): MedicineCompoundingFeeConfigValueType[] | null => {
  const category = medicineCategoryList.find((item) =>
    item.codes.includes(generic_name_code),
  );

  if (!category) {
    return null;
  }

  const feeConfig = medicineCompoundingFeeConfig[category.label];

  if (!feeConfig) {
    return null;
  }

  return feeConfig;
};

export const searchMedicineList: ManagedMedicineItemType[] =
  searchMedicineListJson;

const uniqueMedicationListByMasterCode = Object.values(
  searchMedicineList.reduce<Record<string, ManagedMedicineItemType>>(
    (acc, curr) => {
      if (!acc[curr.master_code]) {
        acc[curr.master_code] = curr;
      }
      return acc;
    },
    {},
  ),
);

export const finasterideSearchList = uniqueMedicationListByMasterCode.filter(
  (medicine) => {
    if (!medicineCategoryList[0].codes) return null;

    if (medicineCategoryList[0].codes.includes(medicine.generic_name_code)) {
      return medicine;
    }

    return null;
  },
);

export const dutasterideSearchList = uniqueMedicationListByMasterCode.filter(
  (medicine) => {
    if (!medicineCategoryList[1].codes) return null;

    if (medicineCategoryList[1].codes.includes(medicine.generic_name_code)) {
      return medicine;
    }

    return null;
  },
);

export const dietShotSearchList = uniqueMedicationListByMasterCode.filter(
  (medicine) => {
    if (!medicineCategoryList[2].codes) return null;

    if (medicineCategoryList[2].codes.includes(medicine.generic_name_code)) {
      return medicine;
    }

    return null;
  },
);

export const eatingAcneMedicineSearchList =
  uniqueMedicationListByMasterCode.filter((medicine) => {
    if (!medicineCategoryList[3].codes) return null;

    if (medicineCategoryList[3].codes.includes(medicine.generic_name_code)) {
      return medicine;
    }

    return null;
  });

export const linimentAcneMedicineSearchList =
  uniqueMedicationListByMasterCode.filter((medicine) => {
    if (!medicineCategoryList[4].codes) return null;

    if (medicineCategoryList[4].codes.includes(medicine.generic_name_code)) {
      return medicine;
    }

    return null;
  });

export const managementMedicineTabList = [
  {
    id: 0,
    label: `전체`,
    codes: [],
  },
  ...medicineCategoryList,
];

// 위고비는 자동수락을 하지 않기에 추가된 데이터
export const autoConfirmedMedicineManagementCategoryConfig: Record<
  string,
  string
> = {
  '159002ATB': '피나스테리드',
  '458801ATB': '두타스테리드',
  '458801ACS': '두타스테리드',
  '612230BIJ': '다이어트 주사',
  '178701ACS': '먹는 여드름약',
  '135639CLQ': '바르는 여드름약',
  '135635CLQ': '바르는 여드름약',
  '135633CLQ': '바르는 여드름약',
  '135602CLQ': '바르는 여드름약',
  '135636CLQ': '바르는 여드름약',
  '371201CCM': '바르는 여드름약',
};

export const medicineManagementCategoryConfig: Record<string, string> = {
  '159002ATB': '피나스테리드',
  '458801ATB': '두타스테리드',
  '458801ACS': '두타스테리드',
  '612230BIJ': '다이어트 주사',
  '708607BIJ': '다이어트 주사',
  '708608BIJ': '다이어트 주사',
  '708609BIJ': '다이어트 주사',
  '708610BIJ': '다이어트 주사',
  '708611BIJ': '다이어트 주사',
  '178701ACS': '먹는 여드름약',
  '135639CLQ': '바르는 여드름약',
  '135635CLQ': '바르는 여드름약',
  '135633CLQ': '바르는 여드름약',
  '135602CLQ': '바르는 여드름약',
  '135636CLQ': '바르는 여드름약',
  '371201CCM': '바르는 여드름약',
};

function convertMedicineNameConfig(medicineList: ManagedMedicineItemType[]) {
  const medicineConfig: Record<string, string> = {};
  medicineList.forEach((medicine) => {
    if (medicine.standard_code && medicine.korean_product_name) {
      medicineConfig[medicine.standard_code] = medicine.korean_product_name;
    }
  });

  return medicineConfig;
}

export const medicineNameConfig: Record<string, string> =
  convertMedicineNameConfig(searchMedicineList);
