const DEFAULT_PROD_SOCKET_API_SERVER_URL =
  'wss://socket.merakiplace.work/pharmacist';
const DEFAULT_DEV_SOCKET_API_SERVER_URL =
  'wss://dev-socket-api.merakiplace.work/pharmacist';

export const SocketServerConstant = () => {
  let socketServerURL;
  switch (process.env.REACT_APP_SERVER) {
    case 'PRODUCTION':
      socketServerURL = DEFAULT_PROD_SOCKET_API_SERVER_URL;
      break;
    case 'PRE_PRODUCTION':
      socketServerURL = DEFAULT_PROD_SOCKET_API_SERVER_URL;
      break;
    case 'DEV':
      socketServerURL = DEFAULT_DEV_SOCKET_API_SERVER_URL;
      break;
    case 'DEBUGGING':
      socketServerURL = DEFAULT_PROD_SOCKET_API_SERVER_URL;
      break;
    case 'LOCAL':
      socketServerURL = DEFAULT_DEV_SOCKET_API_SERVER_URL;
      break;
    default:
      socketServerURL = DEFAULT_DEV_SOCKET_API_SERVER_URL;
      break;
  }
  return socketServerURL;
};
