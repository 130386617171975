import { myDoctorAPI } from '@services/myDoctor';
import { PostTpprocessRecognizeAutoConfirmedItemsParamType } from '@services/myDoctor/types';
import { useMutation } from '@tanstack/react-query';

export const useRecognizeAutoConfirmedItems = () => {
  return useMutation({
    mutationFn: async ({
      tpp_ids,
      should_recognize_all,
    }: PostTpprocessRecognizeAutoConfirmedItemsParamType) => {
      await myDoctorAPI.postTpprocessRecognizeAutoConfirmedItems({
        tpp_ids,
        should_recognize_all,
      });
    },
  });
};
