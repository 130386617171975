export const SPACING_VARIABLES = {
  windowOSMinHeight: 864,
  lnbWidth: 320,
  lnbAdWidth: 320,
  headerHeight: 80,
  updateNudge: 72,
  headerTabHeight: 56,
  xxlSpacing: 48,
  xlSpacing: 32,
  lgSpacing: 20,
  mdSpacing: 16,
  smSpacing: 8,
  borderRadius: 8,
};

export const Z_INDEX = {
  toast: 999999999,
  modal: 200,
  bottomSheet: 160,
  bottomSheetBackdrop: 150,
  updateNudge: 100,
  profileDropdown: 50,
  gnbAutoConfirmedLabel: 35,
  headerTab: 30,
  lnb: 10,
};

export const BREAK_POINTS = {
  size1920: 1920,
  size1440: 1440,
  size1280: 1280,
  size1248: 1248,
  size1125: 1125,
  size1024: 1024,
  size960: 960,
  size720: 720,
};
