import { COLOR } from '@components/atoms';
import { styleVariables } from '@constants';
import { SPACING_VARIABLES, Z_INDEX } from '@constants/styleVariables';
import styled from 'styled-components';

const { BREAK_POINTS } = styleVariables;

export const GNBLayout = styled.div`
  position: relative;
`;

export const GNBMain = styled.main<{ isOpen: boolean }>`
  h1 {
    font-size: 14px;
  }
  position: absolute;
  top: ${SPACING_VARIABLES.headerHeight}px;
`;

export const GNBTopBar = styled.div<{ isVersionUp: boolean }>`
  position: fixed;
  justify-content: space-between;
  top: ${({ isVersionUp }) =>
    isVersionUp ? `${SPACING_VARIABLES.updateNudge}px` : 0};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  z-index: 20;
  width: 100vw;
  height: ${SPACING_VARIABLES.headerHeight}px;
  background-color: ${COLOR['fill/white']};
`;

export const GNBTopBarArea = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const GNBSubTopbar = styled.div<{ isOpen: boolean }>`
  width: 100vw;
  height: ${({ isOpen }) => (isOpen ? 72 : 0)}px;
  background-color: ${COLOR['fill/white']};
  position: fixed;
  top: 88px;
  z-index: 19;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`;

export const GNBLogoArea = styled.div`
  width: 112px;
  margin-right: 24px;
  cursor: pointer;
`;

export const GNBLogo = styled.img`
  width: 35px;
  height: 20px;
  margin-right: 55px;
`;

export const GNBLinkContainerArea = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const GNBLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GNBDivider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${COLOR['border/divider']};
`;

export const GNBLinkArea = styled.div<{ isActive: boolean }>`
  height: ${SPACING_VARIABLES.headerHeight}px;
  display: flex;
  align-items: center;
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${COLOR['fill/black']}` : `2px solid transparent`};

  cursor: pointer;
  position: relative;
  &:hover {
    color: ${COLOR['fill/black']};
    border-bottom: ${({ isActive }) =>
      isActive
        ? `2px solid ${COLOR['fill/black']}`
        : `2px solid ${COLOR['fill/medium']}`};
    span {
      color: ${COLOR['fill/black']};
    }
  }
`;

export const GNBLabelText = styled.span<{ isClickedMenu: boolean }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.01em;
  white-space: nowrap;
  color: ${({ isClickedMenu }) =>
    isClickedMenu ? COLOR['fill/black'] : COLOR.greyscale4};
`;

export const GNBButtonLinkArea = styled.div<{ isActive: boolean }>`
  height: 88px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-bottom: ${({ isActive }) =>
    isActive ? `3px solid ${COLOR['fill/black']}` : `3px solid transparent`};
  font-size: 18px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: ${({ isActive }) =>
    isActive ? COLOR['fill/black'] : COLOR['fill/medium']};
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
  position: relative;
  &:hover {
    font-weight: 700;
    border-bottom: 3px solid ${COLOR['fill/black']};
  }
`;

export const GNBLinkNotification = styled.div<{
  isClickedMenu: boolean;
  isNotificationEmpty: boolean;
}>`
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding: 2px 4px;
  height: 18px;
  border-radius: 4px;
  background: ${({ isClickedMenu, isNotificationEmpty }) =>
    isNotificationEmpty
      ? COLOR.greyscale2
      : isClickedMenu
      ? COLOR['fill/accent']
      : COLOR['label/lightBlue']};
`;

export const GNBLinkNotice = styled.div<{ isClickedMenu: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 6px;
  padding: 2px 4px;
  height: 18px;
  border-radius: 4px;
  background: ${({ isClickedMenu }) =>
    isClickedMenu ? COLOR['fill/accent'] : COLOR['label/lightBlue']};
`;

export const GNBSubLinkContainerArea = styled.div<{ isActive: boolean }>`
  height: 36px;
  border-radius: 20px;
  border: 1.5px solid
    ${({ isActive }) => (isActive ? COLOR['fill/black'] : COLOR['fill/medium'])};
  background-color: ${({ isActive }) =>
    isActive ? COLOR['fill/black'] : COLOR['fill/white']};
  margin-right: 16px;
  padding: 8px 20px;
  font-size: 16px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ isActive }) =>
    isActive ? COLOR['fill/white'] : COLOR['fill/medium']};
  cursor: pointer;
  &:hover {
    color: ${COLOR['fill/white']};
    border: 2px solid ${COLOR['fill/black']};
    background-color: ${COLOR['fill/black']};
    font-weight: 700;
  }
`;

export const GNBDescriptionArea = styled.div``;

export const GNBDescriptionButtonContainer = styled.button<{
  isDescriptionDropdownClicked: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 260px;
  border-radius: 8px;
  background-color: ${({ isDescriptionDropdownClicked }) =>
    isDescriptionDropdownClicked ? COLOR.blue50 : COLOR['fill/light']};
  border: ${({ isDescriptionDropdownClicked }) =>
    isDescriptionDropdownClicked
      ? `1px solid ${COLOR['fill/accent']}`
      : `1px solid ${COLOR['border/outline']}`};
  cursor: pointer;
  :hover {
    border: ${({ isDescriptionDropdownClicked }) =>
      isDescriptionDropdownClicked
        ? `1px solid ${COLOR['fill/accent']}`
        : `1px solid ${COLOR.greyscale6}`};
  }
`;

export const GNBContentArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

export const GNBStatusArea = styled.div`
  cursor: pointer;
`;

export const GNBStatusButtonArea = styled.button<{
  isDescriptionDropdownClicked: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  width: 96px;
  border-radius: 8px;
  background-color: ${({ isDescriptionDropdownClicked }) =>
    isDescriptionDropdownClicked ? COLOR.blue50 : COLOR['fill/light']};
  border: ${({ isDescriptionDropdownClicked }) =>
    isDescriptionDropdownClicked
      ? `1px solid ${COLOR['fill/accent']}`
      : `1px solid ${COLOR['border/outline']}`};
  cursor: pointer;
  :hover {
    border: ${({ isDescriptionDropdownClicked }) =>
      isDescriptionDropdownClicked
        ? `1px solid ${COLOR['fill/accent']}`
        : `1px solid ${COLOR.greyscale6}`};
  }
`;

export const GNBStatus = styled.div<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 20px;
  background-color: ${({ isOpen }) =>
    isOpen ? COLOR['state/success'] : COLOR['state/warning']};
`;

export const GNBTextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const GNBTopTextArea = styled.div``;

export const GNBBottomTextArea = styled.div``;

export const GNBIconArea = styled.div<{
  isDescriptionDropdownClicked: boolean;
}>`
  display: flex;
  align-items: center;
  transition: transform 0.5s;
  transform: rotate(
    ${({ isDescriptionDropdownClicked }) =>
      isDescriptionDropdownClicked ? 180 : 0}deg
  );
`;

export const GNBDescriptionDropdownList = styled.div`
  position: fixed;
  top: 74px;
  width: 260px;
  z-index: ${Z_INDEX.profileDropdown};
  background-color: ${COLOR['fill/white']};
  border: 1px solid ${COLOR['border/outline']};
  border-radius: 8px;
  @media (max-width: ${BREAK_POINTS.size1248}px) {
    right: 20px;
  }
`;

export const GNBDescriptionDropdownItemArea = styled.div<{
  isDivider: boolean;
}>`
  padding: 14px 16px;
  cursor: pointer;
  border-bottom: ${({ isDivider }) =>
    isDivider ? `1px solid ${COLOR.greyscale2}` : 'none'};
  :hover {
    background-color: ${COLOR.greyscale2};
  }
`;

export const DropdownNoticeArea = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const DropdownNotice = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  background: ${COLOR['fill/accent']};
`;

export const FadeAlertBubble = styled.div`
  display: inline-block;
  position: absolute;
  top: 60px;
  left: -50%;
  text-align: center;
  width: 142px;
  padding: 6px 12px;
  background-color: ${COLOR.red500};
  border-radius: 6px;
  z-index: ${Z_INDEX.gnbAutoConfirmedLabel};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(-100%);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${COLOR.red500};
  }

  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
`;
