import { pharmacyPaymentApi } from '@apis/payment';
import { Button, COLOR, Font, modalCommonStyle } from '@components/atoms';
import BDatepicker from '@components/meraki-ui/BDatepicker';
import BFlex from '@components/meraki-ui/BFlex';
import BTypography from '@components/meraki-ui/BTypography';
import {
  CallOutMessage,
  ModalCommonStructure as Modal,
} from '@components/molecules';
import { useDateInput } from '@pages/timeManagementPage/registerOffDayModal/useDateInput';
import { useAppSelector } from '@stores/hooks';
import {
  selectUserInformationPharmacistData,
  selectUserInformationUserData,
} from '@stores/userInformationStore';
import { ReactNode, useState } from 'react';

interface VATModalProps {
  open: boolean;
  onClose: () => void;
  callOutMessage: string;
  title: string | ReactNode;
  VATDescription: string;
}

const VAT_START_LIMIT_TIMESTAMP = 1663340400000;

function VATModal({
  open,
  onClose,
  title,
  callOutMessage,
  VATDescription,
}: VATModalProps) {
  const [isCheckVAT, setIsCheckVAT] = useState(false);
  const [dashboard, setDashboard] = useState<any | null>(null);
  const [isBeforeStartLimit, setIsBeforeStartLimit] = useState(false);
  const [isOneYearOver, setIsOneYearOver] = useState(false);

  const { user_id } = useAppSelector(selectUserInformationUserData);
  const { pharmacy_id } = useAppSelector(selectUserInformationPharmacistData);

  const {
    date: startDate,
    onDateChange: onStartDateChange,
    onDateInputChange: onStartDateInputChange,
  } = useDateInput();

  const {
    date: finishDate,
    onDateChange: onFinishDateChange,
    onDateInputChange: onFinishDateInputChange,
  } = useDateInput();

  const resetDashboard = () => {
    setDashboard(null);
    setIsCheckVAT(false);
  };

  const convertDateToYYYYMMDD = (date: Date | null) => {
    if (!date) return '';
    const targetDate = new Date(date);
    const YYYY = String(targetDate.getFullYear());
    const MM = String(targetDate.getMonth() + 1).padStart(2, '0');
    const DD = String(targetDate.getDate()).padStart(2, '0');

    return `${YYYY}-${MM}-${DD}`;
  };

  const requestVAT = async () => {
    if (!(user_id && pharmacy_id)) {
      alert(
        '알 수 없는 오류가 발생했습니다. 지속적으로 문제가 있는 경우, 고객센터로 연락 부탁드립니다.',
      );
      return;
    }
    try {
      const { data, status } = await pharmacyPaymentApi.getDashBoard({
        is_admin: true,
        to_date: convertDateToYYYYMMDD(finishDate),
        from_date: convertDateToYYYYMMDD(startDate),
        user_id,
        pharmacy_id,
      });
      if (status !== 200) {
        alert(
          '알 수 없는 오류가 발생했습니다. 지속적으로 문제가 있는 경우, 고객센터로 연락 부탁드립니다.',
        );
        return;
      }
      setDashboard(data);
      setIsCheckVAT(true);
    } catch (e) {
      alert(
        '알 수 없는 오류가 발생했습니다. 지속적으로 문제가 있는 경우, 고객센터로 연락 부탁드립니다.',
      );
    }
  };
  const VATDownload = async () => {
    if (!(user_id && pharmacy_id)) {
      alert(
        '알 수 없는 오류가 발생했습니다. 지속적으로 문제가 있는 경우, 고객센터로 연락 부탁드립니다.',
      );
      return;
    }

    const response = await pharmacyPaymentApi.exportDashBoard({
      is_admin: true,
      to_date: convertDateToYYYYMMDD(finishDate),
      from_date: convertDateToYYYYMMDD(startDate),
      user_id,
      pharmacy_id,
    });
    const blob = new Blob([response], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `부가세신고(${convertDateToYYYYMMDD(startDate)}_${convertDateToYYYYMMDD(
        finishDate,
      )}).xlsx`,
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleCTAButtonClick = async () => {
    if (isCheckVAT) {
      await VATDownload();
    } else {
      await requestVAT();
    }
    setIsBeforeStartLimit(false);
    setIsOneYearOver(false);
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (!date) return;
    if (!finishDate) return;

    const targetTimeStamp = new Date(date).getTime();

    if (VAT_START_LIMIT_TIMESTAMP >= targetTimeStamp) {
      const startLimitDate = new Date(VAT_START_LIMIT_TIMESTAMP);
      onStartDateChange(startLimitDate);
      setIsBeforeStartLimit(true);
      resetDashboard();

      return;
    }

    if (isYearDifferenceOverOneYear(date, finishDate)) {
      const { oneYearBefore } = getOneYearBeforeAndAfter(finishDate);
      onStartDateChange(oneYearBefore);
      setIsOneYearOver(true);
      setIsBeforeStartLimit(false);
      resetDashboard();

      return;
    }

    setIsOneYearOver(false);
    setIsBeforeStartLimit(false);
    onStartDateChange(date);
    resetDashboard();
  };

  const handleChangeFinishDate = (date: Date | null) => {
    if (!date) return;
    if (!startDate) return;

    if (isYearDifferenceOverOneYear(startDate, date)) {
      const { oneYearAfter } = getOneYearBeforeAndAfter(startDate);
      onFinishDateChange(oneYearAfter);
      resetDashboard();
      setIsOneYearOver(true);
      setIsBeforeStartLimit(false);
      return;
    }

    onFinishDateChange(date);
    setIsOneYearOver(false);
    setIsBeforeStartLimit(false);
    resetDashboard();
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      modalStyle={{
        ...modalCommonStyle,
        content: { ...modalCommonStyle.content, overflow: 'visible' },
      }}
      modalHeader={
        <Font fontType="h1" color="greyscale6">
          {title}
        </Font>
      }
      modalBody={
        <>
          <BFlex isColumn gap={24}>
            {!isCheckVAT && (
              <div style={{ whiteSpace: 'break-spaces' }}>
                <CallOutMessage>
                  <BTypography size={16} color="mono08" text={callOutMessage} />
                </CallOutMessage>
              </div>
            )}

            <BFlex isFull gap={20}>
              <BFlex isFull isColumn gap={12}>
                <BTypography size={14} color="mono08" text="시작일" />
                <BDatepicker
                  date={startDate}
                  onDateChange={handleChangeStartDate}
                  onDateInputChange={onStartDateInputChange}
                />
              </BFlex>
              <BFlex isFull isColumn gap={12}>
                <BTypography size={14} color="mono08" text="종료일" />
                <BDatepicker
                  date={finishDate}
                  onDateChange={handleChangeFinishDate}
                  onDateInputChange={onFinishDateInputChange}
                />
              </BFlex>
            </BFlex>
            {isOneYearOver && (
              <BTypography
                color="error08"
                size={14}
                text="&bull; 시작일과 종료일 사이의 기간은 최대 1년까지만 가능합니다."
              />
            )}

            {isBeforeStartLimit && (
              <BTypography
                color="error08"
                size={14}
                text="&bull; 시작일은 2022년 9월 17일부터 선택 가능합니다."
              />
            )}
          </BFlex>

          {isCheckVAT && dashboard && (
            <BFlex isColumn gap={24} padding="40px 0 0 0">
              <CallOutMessage>
                <BTypography size={16} color="mono08" text={VATDescription} />
              </CallOutMessage>
              <BFlex isFull gap={20}>
                <BFlex isFull isColumn gap={12}>
                  <BTypography size={14} color="mono08" text="총 매출" />

                  <BTypography
                    size={14}
                    color="mono07"
                    text={`${dashboard.preview.total_amount.toLocaleString()}원`}
                  />
                </BFlex>
                <BFlex isFull isColumn gap={12}>
                  <BTypography size={14} color="mono08" text="총 수수료" />

                  <BTypography
                    size={14}
                    color="mono07"
                    text={`${dashboard.preview.total_commission.toLocaleString()}원`}
                  />
                </BFlex>
                <BFlex isFull isColumn gap={12}>
                  <BTypography size={14} color="mono08" text="정산금액" />

                  <BTypography
                    size={14}
                    color="mono07"
                    text={`${dashboard.preview.total_settlement_amount.toLocaleString()}원`}
                  />
                </BFlex>
              </BFlex>
            </BFlex>
          )}
        </>
      }
      modalFooter={
        <BFlex isFull>
          <Button
            onClick={handleCTAButtonClick}
            backgroundColor={COLOR['fill/accent']}
            width="100%"
            height="56px"
            borderRadius="12px"
            title={
              <Font fontType="body1" color="fill/white">
                {isCheckVAT ? '상세내역 다운로드' : '조회하기'}
              </Font>
            }
          />
        </BFlex>
      }
    />
  );
}

export default VATModal;

const oneYearInMilliseconds = 31536000000; // 1년을 밀리초로 변환
const isYearDifferenceOverOneYear = (startDate: Date, finishDate: Date) => {
  // 날짜 간의 차이를 계산하여 절댓값을 취합니다.
  const difference = Math.abs(startDate.getTime() - finishDate.getTime());

  // 차이가 1년 이상인지 확인합니다.
  return difference >= oneYearInMilliseconds;
};

const getOneYearBeforeAndAfter = (date: Date) => {
  const oneYearBefore = new Date(date.getTime() - oneYearInMilliseconds);
  const oneYearAfter = new Date(date.getTime() + oneYearInMilliseconds);

  return {
    oneYearBefore,
    oneYearAfter,
  };
};
