import { BoxContainer, COLOR } from '@components/atoms';
import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
`;

export const TabArea = styled.div<{ isActive: boolean }>`
  display: flex;
  padding: 0.9375rem 0.5rem;
  align-items: center;
  border-bottom: ${({ isActive }) =>
    isActive ? `3px solid ${COLOR['fill/black']}` : `3px solid transparent`};
  font-size: 1rem;
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};
  text-align: center;
  color: ${({ isActive }) =>
    isActive ? COLOR['fill/black'] : COLOR['fill/medium']};
  cursor: pointer;
  &:hover {
    font-weight: 700;
    border-bottom: 3px solid ${COLOR['fill/black']};
    color: ${COLOR['fill/black']};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const PageText = styled.div`
  margin-bottom: 20px;
`;

export const PageTitleArea = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const PageTitleItem = styled.div`
  margin-right: 8px;
  &:last-child {
    cursor: pointer;
    margin-right: 0;
  }
`;

export const PageTitleIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const ListArea = styled(BoxContainer)`
  width: 1024px;
  padding: 30px;
  &.smallListArea {
    width: 902px;
  }
`;

export const TitleArea = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 20px;
`;

export const OptionArea = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const OptionLeftArea = styled.div``;

export const OptionRightArea = styled.div`
  position: relative;
`;

export const OptionRightAreaDropdownList = styled.div`
  width: 140px;
  height: 120px;
  background-color: ${COLOR['fill/white']};
  border-radius: 8px;
  filter: drop-shadow(0px 1px 20px rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

export const OptionRightAreaDropdownItem = styled.div`
  width: 100%;
  cursor: pointer;
  color: ${COLOR['fill/dark']};
  &:hover {
    color: ${COLOR['fill/black']};
  }
  text-align: center;
`;

export const ListContainer = styled.div``;

export const ListDefaultItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  width: 100%;
`;

export const ListHeaderItem = styled(ListDefaultItem)`
  background-color: ${COLOR.greyscale2};

  text-align: center;
  white-space: break-spaces;
`;

export const IconButtonItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-left: 12px;
  margin-right: 12px;
`;

export const LoaderArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 41px;
  margin: 4px 0;
  padding: 12px;
  background-color: ${COLOR['fill/light']};
  border-radius: 8px;
`;

export const TooltipMessageArea = styled.div``;

export const TooltipMessage = styled.div``;

export const TooltipImage = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 4px;
`;

export const FadeAlertBubble = styled.div`
  position: relative;
  text-align: center;
  width: 152px;
  padding: 6px 12px;
  background-color: ${COLOR.red500};
  border-radius: 6px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid ${COLOR.red500};
  }
`;
