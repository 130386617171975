import { storageHandler } from '@utils';

export const SERVER_URL_KEY = 'merakiServerURL';
const DEFAULT_PROD_MAIN_API_SERVER_URL = 'https://api-prod.merakiplace.work';
const DEFAULT_DEV_MAIN_API_SERVER_URL = 'https://dev-api.merakiplace.work';
const DEFAULT_PRE_PROD_MAIN_API_SERVER_URL =
  'https://api-preprod.merakiplace.work';
const DEFAULT_PROD_PAY_API_SERVER_URL = 'https://prod-pay-api.merakiplace.work';
const DEFAULT_DEV_PAY_API_SERVER_URL = 'https://dev-pay-api.merakiplace.work';

export const ServerConstant = () => {
  const { getLocalStorageState } = storageHandler;
  const localStorageServerURL = getLocalStorageState(SERVER_URL_KEY);
  let serverURL;
  switch (process.env.REACT_APP_SERVER) {
    case 'PRODUCTION':
      serverURL = `${DEFAULT_PROD_MAIN_API_SERVER_URL}/`;
      break;
    case 'PRE_PRODUCTION':
      serverURL = `${DEFAULT_PRE_PROD_MAIN_API_SERVER_URL}/`;
      break;
    case 'DEV':
      serverURL =
        localStorageServerURL || `${DEFAULT_DEV_MAIN_API_SERVER_URL}/`;
      break;
    case 'DEBUGGING':
      serverURL = DEFAULT_PROD_MAIN_API_SERVER_URL;
      break;
    case 'LOCAL':
      serverURL =
        localStorageServerURL || `${DEFAULT_DEV_MAIN_API_SERVER_URL}/`;
      break;
    default:
      serverURL = `${DEFAULT_DEV_MAIN_API_SERVER_URL}/`;
      break;
  }
  return serverURL;
};

export const getServerURL = (type?: 'apis' | 'payment') => {
  const { getLocalStorageState } = storageHandler;
  const localStorageServerURL = getLocalStorageState(SERVER_URL_KEY);

  if (type === 'payment') {
    switch (process.env.REACT_APP_SERVER) {
      case 'PRODUCTION':
        return DEFAULT_PROD_PAY_API_SERVER_URL;
      case 'PRE_PRODUCTION':
        return DEFAULT_PROD_PAY_API_SERVER_URL;
      default:
        return DEFAULT_DEV_PAY_API_SERVER_URL;
    }
  }

  switch (process.env.REACT_APP_SERVER) {
    case 'PRODUCTION':
      return DEFAULT_PROD_MAIN_API_SERVER_URL;
    case 'PRE_PRODUCTION':
      return DEFAULT_PROD_MAIN_API_SERVER_URL;
    default:
      return localStorageServerURL || DEFAULT_DEV_MAIN_API_SERVER_URL;
  }
};
